import React, { useState } from "react";
import axios from "axios";

import { WhatsAppOutlined } from '@ant-design/icons';

const SupportWidget = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [feedback, setFeedback] = useState("");
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async () => {
        if (!title || !feedback) {
            alert("Please fill in all fields.");
            return;
        }

        setLoading(true);

        try {
            const response = await axios.post(
                "https://api.clickup.com/api/v2/list/901207416609/task",
                {
                    name: title,
                    description: feedback,
                },
                {
                    headers: {
                        Authorization: process.env.REACT_APP_CLICK_UP_API_KEY,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.status === 200 || response.status === 201) {
                setSuccess(true);
                setFeedback("");
                setTitle("");
            } else {
                alert("Failed to submit feedback. Please try again.");
            }
        } catch (error) {
            console.error("Error submitting feedback:", error);
            alert("An error occurred. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ position: "fixed", bottom: "8px", right: "20px", zIndex: "9999", }}>
        <div>
            <span
            style={{ 
                position: "fixed",
                left: "20px",
                bottom: "55px", 
                fontWeight: 'bold',
                }}>Chat with us</span>
           <a
            href="https://wa.me/254746804063"
            style={{
            color: "#fff",
            backgroundColor: "#28a745",
            padding: "12px",
            borderRadius: "50%", 
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            left: "10px",
            bottom: "10px", 
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            textDecoration: "none"
            }}
            target="_blank"
            rel="noopener noreferrer"
            >
             <WhatsAppOutlined style={{lineHeight: 1, fontSize: '1.5rem'}}/>
        </a>
        </div>

            {isOpen ? (
                <div
                    style={{
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        background: "#fff",
                        width: "350px",
                    }}
                >
                    {success ? (
                        <div>
                            <p>Thank you for you!</p>
                            <button
                                onClick={() => {
                                    setSuccess(false);
                                    setIsOpen(false);
                                }}
                                style={{
                                    backgroundColor: "#007FFF",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "5px",
                                    padding: "5px 25px",
                                    cursor: "pointer",
                                    marginRight: "10px",
                                }}
                            >
                                Close
                            </button>
                        </div>
                    ) : (
                        <>
                            <h4>Create Support Ticket</h4>
                            <input
                                type="text"
                                placeholder="Title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                style={{
                                    width: "100%",
                                    padding: "6px 8px",
                                    marginBottom: "10px",
                                    borderRadius: "6px",
                                    border: "1px solid rgb(206, 206, 206)",
                                    outline: "none",
                                }}
                            />
                            <textarea
                                placeholder="How can we help?"
                                value={feedback}
                                onChange={(e) => setFeedback(e.target.value)}
                                style={{
                                    width: "100%",
                                    padding: "8px",
                                    height: "150px",
                                    marginBottom: "10px",
                                    borderRadius: "6px",
                                    border: "1px solid rgb(206, 206, 206)",
                                    outline: "none",
                                }}
                            />
                            <button
                                onClick={handleSubmit}
                                disabled={loading}
                                style={{
                                    backgroundColor: "#007FFF",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "5px",
                                    padding: "8px 15px",
                                    cursor: "pointer",
                                    marginRight: "10px",
                                }}
                            >
                                {loading ? "Submitting..." : "Submit"}
                            </button>
                            <button
                                onClick={() => setIsOpen(false)}
                                style={{
                                    backgroundColor: "#dc3545",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "5px",
                                    padding: "8px 15px",
                                    cursor: "pointer",
                                }}
                            >
                                Close
                            </button>
                        </>
                    )}
                </div>
            ) : (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        cursor: "pointer",
                      
                    }}
                    onClick={() => setIsOpen(true)}
                >
                    {/* Text and Icon */}
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px", // Spacing between text and icon
                            fontSize: "13px",
                            color: "#555",
                            fontWeight: "bold",
                            marginBottom: "0px",
                        }}
                    >
                        <span>Support Ticket</span>
                        <span className="material-symbols-outlined" style={{ fontSize: "18px" }}>
                            chat
                        </span>
                    </div>
                    {/* Circular Button */}
                    <button
                        style={{
                            backgroundColor: "#007bff",
                            color: "#fff",
                            border: "none",
                            width: "45px",
                            height: "45px",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            cursor: 'pointer',
                        }}
                    >
                        <span className="material-symbols-outlined" style={{ fontSize: "25px" }}>
                            chat
                        </span>
                    </button>
                </div>
            )}
    
        </div>
    );
};

export default SupportWidget;
