import React, {useEffect, useState} from 'react';
import './homepage.css'
import {getRegisteredNumbers} from '../../apis/RegisterdNumbers'
import { useDispatch, useSelector } from 'react-redux';


import { Table, Spin }from "antd";

const Homepage = () => {

    const dispatch = useDispatch()    
    const action = useSelector((state) => state.action)
    const selector = useSelector((state) => state.auth)
    const userDetails = JSON.parse(selector?.userInfo)

    const [registeredNumbers, setRegisteredNumbers] = useState([])

    useEffect(() => {
      getRegisteredNumbers(dispatch).then(resp => {
        if(resp.status === 200){
          setRegisteredNumbers(resp.data?.data)
        }
       })
  
    }, [dispatch])

    const columns = [
      {
          title: 'Verified Name',
          dataIndex: 'verifiedName',
          key: 'verifiedName',
          width: 70,
          sorter: (a, b) => a.verifiedName.length - b.verifiedName.length,
      },
      {
          title: 'Phone Number',
          dataIndex: 'number',
          key: 'number',
          width: 70,
          sorter: (a, b) => a.number.length - b.number.length,
      },
  ];
  
  
  return (
    <div className="welcome-page">
      
          <p id="greeting-message">Hello 👋, {userDetails?.firstName + " " + userDetails?.lastName}</p>
        
        
          <div className="steps-section">
            <p id="instruction-section-header">Get started with WhatsApp API</p>
            <div id="step-instructions">
                <p style={{fontSize:'16px', paddingBottom:'3px',fontWeight:'500'}}>To join our sandbox test environment, send the word <b>"join {userDetails?.client?.sandboxJoinCode}"</b> to our whatsapp sandbox number
                <b> {selector?.sandBoxNumber}</b>.</p>

                <p style={{fontSize:'16px', paddingBottom:'3px',fontWeight:'500'}}> Alternatively click
                  <a href={`https://wa.me/254110090747?text=join%20${userDetails?.client?.sandboxJoinCode}`} target='_blank'
                    id='join-code-link'  rel="noreferrer">Join Now</a>  
                </p>    

                <p style={{fontSize:'16px', paddingTop:'14px',fontWeight:'500'}} id='intro-message'>To start integrating our powerful WhatsApp APIs with your system, visit our
                <a href="https://docs.lipachat.com/" target='_blank' id='join-code-link' rel="noreferrer">Documentation</a> 
                for more detailed instructions.
                </p> 
            </div>  
          </div>

          <div className='connected-numbers-table'>
            <p className='connected-numbers-table-title'>Connected Live Numbers</p>
            <p className='connected-numbers-table-description'>Below are the live numbers currently connected to your account</p>

            <div className='data-table-section' style={{ height: '100%' }}> 
                <Spin spinning={action.pending}>
                    <Table dataSource={registeredNumbers} columns={columns}
                      pagination={false}
                      scroll={{ y: 'calc(78vh - 200px)',}}
                      sticky
                      className="custom-table"
                    /> 
                  </Spin>   
                </div> 
         </div>


         
     
    </div>
  )
}

export default Homepage