import React, { useEffect, useState} from "react";
import './navbar.css'
import { useDispatch, useSelector  } from 'react-redux';
import { Link, useNavigate, NavLink } from 'react-router-dom';

import { getAccountBalance } from '../../apis/Topup'
import { getUserDetails } from '../../apis/UserAPI';

import { toast } from 'sonner'

const Navbar = ({ toggleSideBar }) => {

  const dispatch = useDispatch()
  let navigate = useNavigate()

  const selector = useSelector((state) => state.auth)
  const userDetails = JSON.parse(selector?.userInfo)
  const [accountBalance, setAccountBalance] = useState()

  const [user, setUser] = useState();

  useEffect(() => {
    getAccountBalance(dispatch).then(resp => {
      if (resp?.data?.status === "success") { 
        setAccountBalance(resp?.data?.data)
      }
    })

    getUserDetails(dispatch).then(resp => {
      if (resp?.status === 200) { setUser(resp?.data?.data) }
      else { toast.error("Unabled to fetch roles!", { theme: "colored" }) }
  })
  }, [dispatch])
  

  const togleProfileMenu = () => {
    var x = document.getElementById("profile-menu-section");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  const logoutUser = () => {
    localStorage.clear()
    navigate('/auth/login')
  }


  return (
    <div className="navbar">

  <div className="balance-section">
      
      <div>
      <p className="user-conversations">   
        <span id="credit-currency">Free Service Conversations Left:</span>
        <span id="credit-amount"> {accountBalance?.freeConversations === "null"? 0 : accountBalance?.freeConversations}</span>
      </p>

      <p className="user-credits">   
        <span id="credit-currency">Credit Balance:</span>
        <span id="credit-amount"> {userDetails?.client?.currency} {accountBalance?.credits}</span>
        
        <Link className='topup-btn' to='/app/topup'><span style={{fontSize:'20px', marginRight:'5px'}} class="material-symbols-outlined">payments</span>Top up</Link>
      </p>
     
      </div>

    </div>

     {/* user profile section */}
     <div className='sidebar-profile-section'>

          <p className='sidebar-profile-btn' onClick={() => togleProfileMenu()}>
            <img src={process.env.PUBLIC_URL + "/images/user.png"} alt="company-logo" style={{width: '40px', marginRight: '5px'}}/> 
            <p><span id='profile-name'>{userDetails?.client?.name ? userDetails?.client?.name : "User Company"}</span><br/> 
            <span id='profile-email'>{user?.username}</span>
            </p>  
          </p>

          <div className='sidebar-profile-links' id='profile-menu-section'>
            <NavLink to='/app/profile' id='profile-link'>
            <span class="material-symbols-outlined">person</span>Profile</NavLink>
            <a href="/auth/login" id='profile-link' onClick={logoutUser}>
            <span class="material-symbols-outlined">logout</span>Logout</a>
          </div>

      </div>


  </div>
  )
}

export default Navbar